<template>
  <!-- 页面name：提醒设置 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">提醒设置</div>
      <div></div>
    </div>
    <div class="form">
      <el-form
        :model="form"
        ref="form"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="提醒方式:">
          <el-checkbox-group v-model="remindListCheck">
            <el-checkbox
              v-for="item in remindList"
              :key="item.label"
              :label="item.label"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="提醒时间:">
          <div
            v-for="(e, index) in remindTimeList"
            :key="index"
            style="margin-bottom: 1.04167vw"
          >
            <template>
              <span style="margin-right: 0.52083vw"> 任务 </span>
              <el-select v-model="e.timing" placeholder="请选择" disabled>
                <el-option
                  v-for="item in timeUnit"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                  disabled
                >
                </el-option>
              </el-select>
              <el-input
                v-input-number-two
                v-model="e.timingValue"
                class="elInput"
                maxlength="80"
                placeholder="请输入"
                @keyup.native="getInputChange(e.timingValue, index, e)"
                clearable
              ></el-input>
              <el-select v-model="e.timeUnit" placeholder="请选择">
                <el-option
                  v-for="item in timeList"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <el-switch
                v-model="e.openFlag"
                style="height:2.08333vw;"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </template>
            <!-- :disabled="index == 0 ? true : false" -->
            <!-- <template>
              <el-button
                icon="el-icon-delete"
                circle
                class="rdelete-btn"
                @click="handleDelete(e, index)"
              >
              </el-button>
            </template> -->
          </div>

          <!-- <el-button
            icon="el-icon-circle-plus"
            @click="handleAddList"
            class="remind-btn"
            >添加提醒</el-button
          > -->
        </el-form-item>
      </el-form>

      <div class="form-btn">
        <el-button class="cancel" @click="onCancel"> 取消 </el-button>
        <el-button type="primary" class="submit" @click="onSubmit('ruleForm')">
          确定
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { stringify } from "qs";
export default {
  data() {
    return {
      remindListCheck: ["2"],
      remindList: [
        { label: "1", name: "App" },
        { label: "2", name: "Pc" },
        { label: "3", name: "飞书" },
      ],
      timeUnit: [
        { label: "1", name: "开始前" },
        { label: "2", name: "开始后" },
        { label: "3", name: "结束前" },
        { label: "4", name: "逾期后" },
      ],
      timeList: [
        { label: "2", name: "天" },
        { label: "1", name: "小时" },
      ],
      remindTimeList: [
        {
          timeUnit: "1", //时间单位
          timing: "1", //提醒时机
          timingValue: "1", //时间值
        },
        {
          timeUnit: "1", //时间单位
          timing: "2", //提醒时机
          timingValue: "1", //时间值
        },
         {
          timeUnit: "1", //时间单位
          timing: "3", //提醒时机
          timingValue: "1", //时间值
        },
         {
          timeUnit: "1", //时间单位
          timing: "4", //提醒时机
          timingValue: "1", //时间值
        },
      ],
  
      form: {
        timeUnit: "",
        timingValue: "",
        timing: "",
      },
      labelWidth: "120px",
    };
  },

  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }

    this.getRemindTimeList();
  },

  methods: {
    //上报数据回显
    getRemindTimeList() {
      let params = {};
      this.$api.getRemindTimeList(params).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.length > 0) {
            this.remindTimeList = [];
            this.remindListCheck = [];
            this.remindTimeList = res.data.data;
            if (res.data.data[0].isApp == "1") {
              this.remindListCheck.push("1");
            }

            if (res.data.data[0].isFeishu == "1") {
              this.remindListCheck.push("3");
            }

            if (res.data.data[0].isSystem == "1") {
              this.remindListCheck.push("2");
            }
          } else {
            this.remindListCheck = ["2"];
            // this.remindTimeList = [];
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //改变输入按键事件
    getInputChange(value, index, item) {
      let resultValue = value.replace(/[^\w\.\/]/gi, "");
      let info = { ...item };
      info.timingValue = resultValue;
      this.$set(this.remindTimeList, index, info);
    },

    //添加
    handleAddList() {
      this.remindTimeList.push(JSON.parse(JSON.stringify(this.form)));
    },

    //删除
    handleDelete(e, index) {
      this.remindTimeList.splice(index, 1);
    },

    //取消
    onCancel() {
      this.$router.go(-1);
    },

    //提交
    onSubmit() {
      let isApp = "0";
      let isFeishu = "0";
      let isSystem = "0";
      console.log(this.form.timingValue,'完善提醒时间')
      //  let index = this.remindTimeList.every((item) => {
      //     return item.timingValue != "";
      //   });
      //   if (!index) {
      //     this.$message.error("请完善提醒时间");
      //     return;
      //   }
      // if(this.form.timingValue==''){
      //   this.$message.error("请完善提醒时间");
      //   return;
      // }

      if (this.remindListCheck.length > 0) {
         let index = this.remindTimeList.every((item) => {
          return item.timingValue != "";
        });
        if (!index) {
          this.$message.error("请完善提醒时间");
          return;
        }
        if (this.remindTimeList.length > 0) {
          this.remindListCheck.map((item) => {
            if (item == "1") {
              isApp = "1";
            } else if (item == "3") {
              isFeishu = "1";
            } else if (item == "2") {
              isSystem = "1";
            }
          });
// remindTimeList
          let arry = this.remindTimeList;
          let arryNew = [];
          arry.map((item, index) => {
            arryNew.push(
              Object.assign({}, item, {
                isApp: isApp,
                isFeishu: isFeishu,
                isSystem: isSystem,
                openFlag:item.openFlag == true ? 1:item.openFlag == 1? 1:0
              })
            );
          });
          console.log(arryNew);
          this.getRemindTimeSave(arryNew);
        } else {
          this.$message.error("请完善提醒时间");
        }
      } else {
        this.$message.error("请完善提醒方式");
      }
    },

    //保存
    getRemindTimeSave(arryNew) {
      let params = {
        remindTimeList: arryNew,  
      };
      this.$api.getRemindTimeSave(params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("设置成功");
          this.getRemindTimeList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  padding: 34px 30px 30px 30px;
  .form-header-btn {
    margin-bottom: 24px;
  }

  /deep/.el-select {
    width: 120px;
  }

  /deep/.el-input {
    width: 100px;
    margin-right: 20px;
  }

  /deep/.el-button.is-circle {
    width: 40px;
    border-radius: 10%;
    border-color: transparent;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #2a00b2;
    background: #2a00b2;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #2a00b2;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .form {
    width: 80%;
    margin-top: -10px;

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
  }

  .rdelete-btn {
    font-size: 16px;
  }

  .remind-btn {
    padding: 8px 16px;
    font-size: 12px;
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 104px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #2a00b2;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
.el-switch__core{
  height: 40px;
}
</style>
